/* GRID */

.twelve {
  width: 100%;
}
.eleven {
  width: 91.53%;
}
.ten {
  width: 83.06%;
}
.nine {
  width: 74.6%;
}
.eight {
  width: 66.13%;
}
.seven {
  width: 57.66%;
}
.six {
  width: 49.2%;
}
.five {
  width: 40.73%;
}
.four {
  width: 32.26%;
}
.three {
  width: 23.8%;
}
.two {
  width: 15.33%;
}
.one {
  width: 6.866%;
}

/* COLUMNS */

.col {
  display: block;
  float: left;
  margin: 1% 0 1% calc(1.6%);
}

.col:first-of-type {
  margin-left: 0;
}

.container-c {
  width: 100%;
  height: 75vh;
  position: relative;
  text-align: center;
}

/* CLEARFIX */

.cf::before,
.cf::after {
  content: "";
  display: table;
}

.cf::after {
  clear: both;
}

.cf {
  zoom: 1;
}

.row {
  margin: 30px 0;
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #cbcbcb;
  justify-content: center;
}

.three {
  /* background-color: #eee; */
  padding: 50px 0;
}

/* ALL LOADERS */

.loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

/* LOADER 3 */
#loader-3::before,
#loader-3::after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  left: calc(50% - 10px);
  background-color: transparent;
  background-image: url("../../Assets/Images/Lovepik_com-400307343-litchi.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
  animation: squaremove 1s ease-in-out infinite;
}


#loader-3::after {
  bottom: 0;
  animation-delay: 0.5s;
}

@keyframes squaremove {
  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }

  25% {
    transform: translate(40px, 40px) rotate(45deg);
  }

  50% {
    transform: translate(0px, 80px) rotate(0deg);
  }

  75% {
    transform: translate(-40px, 40px) rotate(45deg);
  }
}
