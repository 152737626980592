@font-face {
  font-family: roboto;
  src: url("./Assets/Fonts/roboto-family/roboto.regular.ttf");
}
body {
  margin: 0;
  padding: 0;
  /* font-family: roboto; */
  font-family: "Calibri";
  background-color: #e0e0e0;
  color: #2c2c2c;
}

a {
  text-decoration: none;
}
.container {
  margin-top: 50px;
  width: auto;
  padding: 0px 20px;
}
.red-error {
  color: rgb(255, 30, 30);
}
.success {
  color: rgb(0 153 0);
}
.text-end {
  text-align: end;
}
.btn {
  cursor: pointer;
  padding: 5px 15px;
  border: none;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.th-id{
 width: 50px;
}
.th-date{
width: 100px;
}
.th-contact{
width: 100px;
}

.btn img {
  width: 20px;
}

.titre-contenu {
  font-size: 24px;
}
.text-center {
  text-align: center;
}

/* pour mobile */
@media screen and (max-width: 440px) {
  .container {
    padding: 0px 10px;
  }
  .titre-contenu {
    font-size: 16px;
  }
  .btn {
    border-radius: 0px;
    padding: 2px 6px;
    font-size: 12px;
  }
  .btn img {
    width: 18px;
  }
}
