.input-quantite-3 {
  display: flex;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 2px;
  align-items: center;
  justify-content: space-between;
}
.input-quantite-3 .supp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.input-quantite-3 .supp input {
  width: 40px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  color: #d9d9d9;
  cursor: pointer;
  background-color: rgb(250, 48, 48);
}
.input-quantite-3 .frs input {
  display: flex;
  flex-direction: column;
  width: 60px;
  padding: 10px;
  margin-top: 0px;
}

.input-quantite-3 .frs select {
  width: 100px;
}

.btn-more {
  padding: 5px 10px;
  background-color: rgb(0, 128, 128);
  color: #fff;
}
.content-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #535353;
}
.info {
  color: rgb(0, 74, 221);
  font-weight: bold;
}

.detail-palette {
  padding: 20px;
}
.titre-table {
  background-color: #535353;
}
.titre-table th {
  color: #d9d9d9;
}
/* pour mobile */
@media screen and (max-width: 440px) {
  .input-quantite-3 .frs input,
  .input-quantite-3 .frs select {
    width: 50px;
    margin-top: 0px;
  }
}
