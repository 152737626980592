.popup-facture {
  background-color: #eaeaea;
  width: 500px;
  border-radius: 5px;
  animation: slideIn 0.3s ease-in-out forwards;
}
.facture-container {
  padding: 0px;
}
.header-facture {
  display: flex;
}

.header-facture div {
  text-align: left;
  padding: 20px;
  width: 50%;
}

.facture-details {
  padding: 0px 20px;
  text-align: left;
}

.table-facture {
  padding: 20px;
}
