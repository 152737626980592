.page{
    display: flex;
}
.contenu{
    background-color: #e0e0e0;
    width: 100%;
    margin-top: 25px;
    min-height: 100vh;
}

.mobile{
    display: none;
}

/* Styles Responsive */
@media (max-width: 800px) {
    .mobile{
        display: block;
    }
    .desktop{
        display: none;
    }
  }
  