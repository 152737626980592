@font-face {
  font-family: roboto;
  src: url("../../Assets/Fonts/roboto-family/roboto.regular.ttf");
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-family: roboto;
  background-color: #470000;
  z-index: 3;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}
.container-nav {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 40px;
}

.btn-ico{
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.logo {
  font-size: 18px;
}

.user {
  display: flex;
  align-items: center;
}

.user img {
  width: 20px;
}
.user div {
  /* margin-left: 8px; */
  font-size: 12px;
  font-weight: lighter;
}

/* Tooltip container */
.user-icon {
  position: relative;
  display: inline-block;
}

.user-icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Tooltip text */
.tooltip {
  visibility: hidden;
  opacity: 0;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px 8px;
  border-radius: 4px;
  position: absolute;
  top: 125%; /* Position below the icon */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Arrow above the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}


/* pour mobile */
@media screen and (max-width: 440px) {
  .container-nav {
    padding: 10px 20px;
  }
  .logo {
    font-size: 16px;
  }
}
