@font-face {
  font-family: roboto_lighter;
  src: url("../../Assets/Fonts/roboto-family/roboto.light.ttf");
}

.sidebar {
  position: fixed;
  top: 38px;
  z-index: 2;
  left: 0;
  /* background-color: #022633be; */
  background-color: #29000D;
  height: 30px;
  width: 270px;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-85%);
}

.sidebar .menubar .menubar-items a,
.sidebar .menubar .menubar-items .btn {
  display: none;
}

.navigation {
  color: #cbf1ffbe;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
}

.navigation div {
  font-family: "calibri";
  font-size: 14px;
}

.navigation img {
  width: 18px;
  cursor: pointer;
}

.open {
  transform: translateX(0);
  min-height: 90vh;
  height: auto !important;
  /* background-color: #022633; */
  background-color: #29000D;
  position: relative;
}
.open .menubar .menubar-items a,
.open .menubar .menubar-items .btn {
  display: block !important;
}

.menubar {
  padding: 0px 4px;
}
ul,
li {
  margin: 0px;
  padding: 0px;
}

.menubar-items a li {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  width: 190px;
}
.menubar-items a li img {
  width: 20px;
}
.menubar-items a li label {
  margin-left: 12px;
  color: #FFF5F2;
  font-size: 14px;
  font-family: 'calibri';
  /* font-weight: lighter; */
}
ul a li {
  margin: 8px;
}
.active li {
  background-color: rgb(0 140 171);
  margin: 8px;
  border-radius: 3px;
  cursor: pointer;
}

.btn-logout {
  width: 84%;
  margin: 5px;
  padding: 10px;
  background-color: rgb(10, 92, 185);
  color: #fff;
  text-align: center;
}

/* pour Tablette */
@media screen and (max-width: 800px) {
  .sidebar {
    width: 240px;
    position: absolute;
    /* background-color: #022633dc; */
    
  }
}

/* pour mobile */
@media screen and (max-width: 440px) {
  .sidebar {
    width: 240px;
    position: absolute;
    /* background-color: #022633dc; */
  }
  .navigation div {
    font-size: 10px;
  }
  .sidebar {
    top: 40px;
    transform: translateX(-84%);
  }
  .open {
    transform: translateX(0);
  }
  .menubar-items a li {
    width: 200px;
  }
  .menubar-items a li label {
    margin-left: 8px;
    font-size: 12px;
  }
  .menubar-items a li img {
    width: 16px;
  }
}
