.btn-payer {
  background-color: rgb(21, 180, 0);
  color: #fff;
}

.input-quantite {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.input-quantite div {
  width: 50%;
}

.input-quantite div input {
  width: 120px;
}
.btn-ajout {
  background-color: #127ee3;
  border: none;
  cursor: pointer;
  padding: 3px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-ajout img {
  width: 18px;
}

/* pour mobile */
@media screen and (max-height: 640px) {
  .form-popup {
    max-height: 240px;
    overflow-y: scroll;
  }
}

/* pour mobile */
@media screen and (max-height: 440px) {
  .form-popup {
    max-height: 120px;
  }
}
