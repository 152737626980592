.head-commande {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.head-commande input, .head-commande button {
  margin-top: 5px;
}

.header {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
}

.header h1 {
  margin: 0;
  padding: 8px;
  font-size: 18px;
}

.cadre-option {
  border-radius: 4px 4px 0px 0px;
  color: #d9d9d9;
  background-color: #323232;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.option-view {
  background-color: #00922c;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 100%;
}
.option-view img {
  width: 10px;
}
.view-open {
  background-color: #e36b25;
}
/* Style pour le conteneur de filtres avec transition plus lente */
.filter-container {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transform: translateY(-20px); /* Commence légèrement au-dessus */
  transition: max-height 0.6s ease, opacity 0.6s ease, transform 0.6s ease;
}

.filter-container.show {
  max-height: 300px; /* Ajustez selon le contenu */
  opacity: 1;
  transform: translateY(0); /* Ramène à la position initiale */
}

.menu-filtre {
  width: 200px;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: center;
}

.menu-filtre-search {
  /* width: 200px; */
  display: flex;
  align-items: center;
}

.btn-commande {
  cursor: pointer;
  /* border-radius: 5px; */
  border: 1px solid #64646460;
  background-color: #127ee3;
  padding: 6px 12px;
}

.btn-commande div {
  color: #fff;
  margin-left: 5px;
}

.color-money {
  color: #127ee3;
  font-weight: bold;
}

.excel {
  background-color: #00922c;
  color: #fff;
}

.btn-commande img {
  width: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  background-color: #fff;
}

.cadre-table-scroll {
  display: inline-block;
  max-height: 32.8em;
  overflow-y: auto;
  width: 100%;
}

th,
td {
  border: 1px solid #fff;
  padding: 8px;
  text-align: left;
  font-weight: 400;
}
tbody {
  color: #3b3b3b;
}

thead {
  background-color: #b25e5e;
}
thead tr th {
  color: #fff;
}

th {
  color: #333333;
}


.btn-delete {
  cursor: pointer;
  padding: 3px;
  background-color: #b90a28;
}

.btn-edit {
  margin-left: 4px;
  padding: 3px;
  background-color: #127ee3;
}

.btn-print {
  margin-left: 4px;
  /* padding: 3px; */
  cursor: pointer;
  background-color: #00922c;
  color: #fff;
}

.btn-print:hover{
  background-color: #004414;
}

.option {
  display: flex;
  justify-content: center;
}

/* popup */
.cadre-popup {
  background-color: #10101096;
  position: fixed;
  backdrop-filter: blur(2px);
  top: 0;
  z-index: 3;
  color: #101010;
  left: 0;
  width: 98.5vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.span-delete {
  color: #127ee3;
  font-weight: bold;
}
.titre-contenu {
  margin-top: 20px;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.popup {
  background-color: #eaeaea;
  width: 400px;
  border-radius: 5px;
  animation: slideIn 0.3s ease-in-out forwards;
}
.popup p {
  margin: 0px;
  padding: 20px;
  text-align: center;
}
.titre-popup {
  font-weight: bold;
}
.bar-popup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #101010;
  padding: 10px 10px;
}
.btn-close {
  background-color: #ec052c;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
}
.alert-action {
  display: flex;
  justify-content: end;
  padding: 20px;
}
.btn-annuler {
  background-color: #6b6b6b;
  color: #fff;
  padding: 10px 12px;
}

.btn-confirmer {
  background-color: #127ee3;
  color: #fff;
  padding: 10px 12px;
  margin-left: 4px;
}
form {
  padding: 20px 20px 0px 20px;
}
form label {
  color: #535353;
}
input,
select {
  width: -webkit-fill-available;
  /* margin-top: 4px; */
  background-color: #fff;
  padding: 6px 12px;
  /* margin-bottom: 10px; */
  color: #003a70;
  border: 1px solid #64646460;
  /* border-radius: 5px; */
}

input:focus,
select:focus {
  outline: none;
  border-color: #ababab;
}
select {
  width: 100%;
}
input:disabled {
  background-color: #f0f0f0; /* Couleur de fond */
  color: #aaa; /* Couleur du texte */
  cursor: not-allowed; /* Curseur pour indiquer qu'il est désactivé */
}

.autre-input {
  display: flex;
  align-items: center;
}
.autre-input input {
  width: 20px;
  margin: 5px;
}
/* notification */
.container-notif {
  position: absolute;
  bottom: 0;
  margin-bottom: 40px;
  z-index: 4;
  right: 0; /* Toujours à droite */
  opacity: 0; /* Opacité initiale */
  transition: opacity 0.9s ease; /* Transition pour l'opacité */
  pointer-events: none; /* Empêche l'interaction lorsqu'elle est invisible */
}

.container-notif.show {
  opacity: 1; /* Opacité à 1 lorsqu'elle est visible */
  pointer-events: auto; /* Permet l'interaction lorsqu'elle est visible */
}

.container-notif.hide {
  opacity: 0; /* Opacité pour l'état caché */
  pointer-events: none; /* Empêche l'interaction */
}

.container-notif .success {
  background-color: #00922cbb;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #004d17;
  color: white; /* Couleur de texte */
  font-weight: 100;
}

.container-notif .error {
  background-color: #920000bb;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #4d0000be;
  color: white; /* Couleur de texte */
  font-weight: 100;
}

/* facture */
.cadre-facture {
  background-color: #fcfcfcd8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  height: 100vh;
  display: none;
}
.head-facture {
  padding: 50px 40px;
  display: flex;
  justify-content: end;
}
.cadre-facture .info {
  display: flex;
  justify-content: space-between;
}
.info {
  padding: 0px 40px;
}
.info div {
  display: flex;
  flex-direction: column;
  width: 250px;
}

/* pour Ipad */
@media screen and (max-width: 895px) {
  .menu-filtre {
    width: 160px;
  }


}
@media screen and (max-width: 745px) {
 
  .menu-filtre {
    width: -webkit-fill-available;
    margin-top: 5px;
  }
  .menu-filtre-search {
    width: -webkit-fill-available;
    margin-top: 5px;
  }
  .cadre-table-scroll {
    overflow-x: scroll;
  }

}

/* pour mobile */
@media screen and (max-width: 440px) {
  table {
    font-size: 12px;
  }
  input, select{
    font-size: 14px;
    width: -webkit-fill-available ;
  }
  .btn-commande {
    font-size: 12px;
  }
  .titre-popup {
    font-size: 16px;
  }
  .popup {
    width: 340px;
  }
 
  form label {
    font-size: 14px;
  }

  .container-notif .success {
    padding: 10px;
    border-radius: 3px;
    font-size: 12px;
  }

  .container-notif .error {
    padding: 10px;
    border-radius: 3px;
    font-size: 12px;
  }
}
