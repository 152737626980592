.container-statistique {
  width: 94%;
  margin-top: 20px;
  padding: 20px;
}

.card-container {
  display: flex; /* Utiliser flexbox pour aligner les cartes sur une ligne */
  flex-wrap: wrap; /* Permettre aux cartes de passer à la ligne si nécessaire */
  justify-content: space-between; /* Espacer les cartes uniformément */
  margin-bottom: 24px;
}

.card {
  background-color: #f9f9f9;
  border: 2px solid rgb(255, 255, 255);
  /* border-radius: 8px; */
  padding: 10px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 140px; /* Largeur minimum pour les cartes */
  margin: 5px; /* Marge entre les cartes */
  background: #003a70;
}

.card h4 {
  font-weight: 500;
  margin: 0 0 10px;
  color: #fff;
}

.card p {
  margin: 8px;
  /* font-weight: bold; */
  font-size: 18px;
  color: #e3e025;
}

/* Styles Responsive */
@media (max-width: 600px) {
  .container-statistique {
    width: 100%;
  }
  .card {
    padding: 5px;
    width: 160px;
  }
  .card h4 {
    font-size: 14px;
  }

  .card p {
    font-size: 16px;
  }
}
