* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.container-logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #181717;
}
.container-logo img {
  width: 180px;
  margin-bottom: 10px;

}

.container-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgb(73 30 37);
}
.container-login img{
  width: 20em;
}
.screen {
  background-color: #ffffff;
  position: relative;
  height: 500px;
  width: 350px;
}
.login {
  width: 330px;
  padding: 30px;
}
.login__field {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
}
.login__input {
  width: 100%;
  border: 1px solid #dedede;
  color: #535353;
  padding: 8px;
  font-size: 14px;
  transition: 0.2s;
}
.login__submit {
  font-size: 14px;
  padding: 8px;
  border: 1px solid #d4d3e8;
  font-weight: 700;
  width: 100%;
  cursor: pointer;
  background-color: #b90a28;
  transition: 0.2s;
  color: white;
}
.login__submit:hover {
  background-color: #b90a28;
  color: white;
}
.error {
  color: #cf0000;
  font-size: 14px;
}
.login__input:active,
.login__input:focus,
.login__input:hover {
  outline: none;
  border-bottom-color: #b90a28;
}

.screen__background__shape {
  transform: rotate(45deg);
  position: absolute;
}

.screen__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connect {
  background-color:#b90a28;
  cursor: not-allowed;
  color: #ffffff8a;
}
.logo_hita{
  width: 90px!important;
}


footer {
text-align: center;
  color: #333;
  padding: 20px;
  height: 170px;
  width: 100%;
}

