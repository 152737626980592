.btn-facture{
    color: #fff;
    background-color: blueviolet;
}

.select-all{
    display: flex;
    align-items: center;
    background-color: #101010;
    padding: 6px 12px;
    color: #d9d9d9;
}

.select-all input{
    width: 50px;
}